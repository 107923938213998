/**
 * Component to hold signup news for SignUpNews Section
 *
 * @module views/components/SignUpNews
 * @memberof -Common
 */
import './SignUpNews.scss';

import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Text from '@ulta/core/components/Text/Text';

import ActionGroup from '../ActionGroup/ActionGroup';

/**
   * Represents a SignUpNews component
   *
   * @method
   * @param {SignUpNewsProps} props - React properties passed from composition
   * @returns SignUpNews
   */
export const SignUpNews = React.forwardRef( ( props, _ ) => {

  const { title, subtitle, actionGroup, backgroundColor } = props;
  return (

    <div className='SignUpNews'>
      <div className='SignUpNews__background'>
        <div
          className={ classNames( 'SignUpNews__content', {
            [`SignUpNews__backgroundColor--${ backgroundColor }`]: backgroundColor
          } ) }
        >
          {
            title &&
            <div className='SignUpNews__title'>
              <Text htmlTag={ title.htmlTag }
                textStyle={ title.textStyle }
              >
                { title.text }
              </Text>
            </div>
          }

          <div className='SignUpNews__subTitleWrapper'>
            { subtitle &&
            <div className='SignUpNews__subTitle'>
              <Text
                textStyle='body-3'
              >
                { subtitle }
              </Text>
            </div> }
            <div className='SignUpNews__link'>
              { ( ( actionGroup.action1?.label ) || ( actionGroup.action2?.label ) ) && (
                <ActionGroup
                  action1={ actionGroup.action1 }
                  action2={ actionGroup.action2 }
                  action1Style={ actionGroup.action1Style }
                  action2Style={ actionGroup.action2Style }
                />
              ) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} );

/**
   * Property type definitions
   * @typedef SignUpNewsProps
   * @type {object}
   * @property {object} title - sets the Title
   * @property {string} subtitle - sets the Subtitle
   * @property {object} actionGroup - Set the action Group for the SignUpNews
   */
export const propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  actionGroup: PropTypes.object.isRequired
};


SignUpNews.propTypes = propTypes;
SignUpNews.displayName = 'SignUpNews';

export default SignUpNews;